export const getNoun = function (nouns, num) {
  const cases = [2, 0, 1, 1, 1, 2];

  return nouns[(num % 100 > 4 && num % 100 < 20) ? 2 : cases[(num % 10 < 5) ? num % 10 : 5]];
};

export const nl2br = function (text) {
  return typeof text === 'string'
    ? text.replace(/\r\n|\r|\n/g, '<br>')
    : '';
};
