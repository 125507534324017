<template>
  <v-container
    fluid
    class="app-page app-page-primary px-0 px-md-3"
    :class="limitedHeight ? '' : 'overflow-y-auto'"
  >
    <div class="app-page__progress">
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
        class="app-page__progress-bar"
      />
    </div>

    <div
      v-if="!!$slots['header']"
      class="app-page__header align-sm-center"
    >
      <slot name="header" />
    </div>

    <div
      class="app-page__body"
      :class="bodyOverflow ? '' : 'overflow-hidden'"
    >
      <slot name="default" />
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'AppPagePrimary',

    props: {
      limitedHeight: {
        type: [Boolean],
        default: true,
      },

      bodyOverflow: {
        type: [Boolean],
        default: false,
      },

      isLoading: {
        type: [Boolean],
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .app-page__header {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding: 8px;
  }

  .app-page__progress {
    position: relative;
    z-index: 1;
  }

  .app-page__progress-bar {
    padding-bottom: 4px;
    position: absolute;
    width: 100%;
  }

  .app-page__body {
    height: 100%;
  }
</style>
