export const getMoveIconName = function (moveType) {
  switch (moveType) {
    case 'Run':
      return 'mdi-run';

    case 'Ride':
      return 'mdi-bike';

    case 'NordicSki':
      return 'mdi-ski';

    case 'RollerSki':
      return 'mdi-ski';

    case 'Walk':
      return 'mdi-walk';

    case 'Hike':
      return 'mdi-hiking';

    case 'Yoga':
      return 'self_improvement';

    case 'Workout':
    case 'WeightTraining':
      return 'fitness_center';

    default:
      return 'mdi-timer-outline';
  }
};

export const convertSecondsToHoursAndMinutes = function (seconds) {
  const h = Math.trunc(seconds / 3600);
  const m = Math.trunc((seconds - h * 3600) / 60);

  return `${h}ч. ${m}м.`;
};

export const convertSecondsToHMMSS = function (seconds, withHours = true) {
  const h = withHours ? Math.trunc(seconds / 3600) : 0;
  const m = Math.trunc((seconds - h * 3600) / 60);
  const s = Math.trunc(seconds - h * 3600 - m * 60);

  const mm = m > 9 ? m : `0${m}`;
  const ss = s > 9 ? s : `0${s}`;

  return `${withHours ? `${h}:` : ''}${mm}:${ss}`;
};

export const getSummaryData = function (daysData) {
  const result = {
    runDistance: 0,
    bikeDistance: 0,
    hikingDistance: 0,
    skiDistance: 0,
    walkDistance: 0,
    runClimb: 0,
    bikeClimb: 0,
    hikingClimb: 0,
    skiClimb: 0,
    walkClimb: 0,
    runTime: 0,
    bikeTime: 0,
    hikingTime: 0,
    skiTime: 0,
    walkTime: 0,
    yogaTime: 0,
    workoutTime: 0,
    allTime: 0,
  };

  daysData.forEach((day) => {
    day.moves.forEach((move) => {
      result.allTime += move.elapsed_time;

      switch (move.type) {
        case 'Run':
          result.runClimb += move.total_elevation_gain;
          result.runDistance += move.distance;
          result.runTime += move.elapsed_time;
          break;

        case 'Ride':
          result.bikeClimb += move.total_elevation_gain;
          result.bikeDistance += move.distance;
          result.bikeTime += move.elapsed_time;
          break;

        case 'NordicSki':
        case 'RollerSki':
          result.skiClimb += move.total_elevation_gain;
          result.skiDistance += move.distance;
          result.skiTime += move.elapsed_time;
          break;

        case 'Walk':
          result.walkClimb += move.total_elevation_gain;
          result.walkDistance += move.distance;
          result.walkTime += move.elapsed_time;
          break;

        case 'Hike':
          result.hikingClimb += move.total_elevation_gain;
          result.hikingDistance += move.distance;
          result.hikingTime += move.elapsed_time;
          break;

        case 'Yoga':
          result.yogaTime += move.elapsed_time;
          break;

        case 'Workout':
        case 'WeightTraining':
          result.workoutTime += move.elapsed_time;
          break;
      }
    });
  });

  return result;
};
